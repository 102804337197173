import React, { ReactElement } from "react"
import { Link } from "gatsby"

function Success(): ReactElement {
  return (
    <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg
              className="h-6 w-6 text-green-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Contact submission successful
            </h3>
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500">
                Thank you for your message.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <span className="flex w-full rounded-md shadow-sm">
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green text-base leading-6 font-medium text-white shadow-sm hover:bg-shadowgreen focus:outline-none focus:border-outline focus:shadow-outlinegreen transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              <Link to="/">Go back to Nelson's Computer Help</Link>
            </button>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Success
